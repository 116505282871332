<template>
    <CRow>
        <CCol col="12" xl="12">
            <CCard>
                <CCardHeader>Siparişler</CCardHeader>
                <CCardBody>
                    <CRow class="mb-5">
                        <CCol col="6" sm="4" md="2" xl class="mb-3 mb-xl-0">
                            <CButton
                                :pressed="this.activeStatus === 'all'"
                                block
                                variant="outline"
                                color="primary"
                                v-on:click="changeStatus('all')"
                            >
                                Tümü
                            </CButton>
                        </CCol>
                        <CCol col="6" sm="4" md="2" xl class="mb-3 mb-xl-0">
                            <CButton
                                :pressed="this.activeStatus === 'waiting-for-approval'"
                                block
                                variant="outline"
                                color="primary"
                                v-on:click.stop="changeStatus('waiting-for-approval')"
                            >
                                Kargolanacak
                            </CButton>
                        </CCol>
                        <CCol col="6" sm="4" md="2" xl class="mb-3 mb-xl-0">
                            <CButton
                                :pressed="this.activeStatus === 'sent'"
                                block
                                variant="outline"
                                color="primary"
                                v-on:click="changeStatus('sent')"
                            >
                                Tamamlanmış
                            </CButton>
                        </CCol>
                        <CCol col="6" sm="4" md="2" xl class="mb-3 mb-xl-0">
                            <CButton
                                :pressed="this.activeStatus === 'returned'"
                                block
                                variant="outline"
                                color="primary"
                                v-on:click="changeStatus('returned')"
                            >
                                İade Edilmiş
                            </CButton>
                        </CCol>
                    </CRow>

                    <CAlert v-on:click="closeAlert()" v-if="alert.isOpen" show :color="alert.color">
                        {{ alert.message }}
                    </CAlert>
                    <CRow class="mb-5">
                        <CCol col="3">
                            <CInput
                                label="İsim"
                                horizontal
                                autocomplete="name"
                                :value.sync="searchName"
                            />
                        </CCol>
                        <CCol col="3">
                            <CInput
                                label="E-posta"
                                horizontal
                                autocomplete="email"
                                :value.sync="searchEmail"
                            />
                        </CCol>
                        <CCol col="3">
                            <CInput
                                label="Telefon"
                                horizontal
                                autocomplete="phone"
                                :value.sync="searchPhone"
                            />
                        </CCol>
                        <CCol col="3">
                            <CButton
                                type="button"
                                size="sm"
                                color="primary"
                                v-on:click="search()"
                            >
                                <CIcon name="cil-search"/> Ara
                            </CButton>
                        </CCol>
                    </CRow>
                    <CDataTable
                        hover
                        striped
                        :items="orders"
                        :fields="fields"
                        clickable-rows
                        @row-clicked="rowClicked"
                    >
                        <template #fullName="data">
                            <td>
                                {{ data.item.author.firstname }} {{ data.item.author.lastname }}
                            </td>
                        </template>
                        <template #status="data">
                            <td>
                                {{ getOrderStatus(data.item.status) }}
                            </td>
                        </template>
                        <template #paymentMethod="data">
                            <td>
                                {{ getPaymentMethod(data.item.paymentMethod) }}
                            </td>
                        </template>
                        <template #info="data">
                            <td>
                                <CBadge v-if="data.item.surveillances && data.item.surveillances.length > 0" :color="'warning'" v-on:click.stop="">
                                    {{ data.item.surveillances.length }} Adet şüpheli hareket
                                </CBadge>
                            </td>
                        </template>
                        <template #surveillance="data">
                            <td>
                                <CButton
                                    block
                                    variant="outline"
                                    color="info"
                                    v-on:click.stop="redirectToSurveillanceCreate(data.item.id)"
                                >
                                    Şüpheli hareket
                                </CButton>
                            </td>
                        </template>
                        <template #changeStatus="data">
                            <td>
                                <CButton
                                    v-if="['waiting-for-approval', 'returned', 'rejected'].indexOf(data.item.status) > -1"
                                    block
                                    variant="outline"
                                    :color="data.item.status === 'waiting-for-approval' ? 'success' : 'warning'"
                                    v-on:click.stop="changeOrderStatus('sent', data.item.id)"
                                >
                                    Kargola
                                </CButton>
                                <CButton
                                    v-if="data.item.status === 'sent'"
                                    block
                                    variant="outline"
                                    color="danger"
                                    v-on:click.stop="changeOrderStatus('giveback', data.item.id)"
                                >
                                    İade Edildi
                                </CButton>
                                <CButton
                                    v-if="data.item.status === 'waiting-for-approval'"
                                    block
                                    variant="outline"
                                    color="danger"
                                    v-on:click.stop="changeOrderStatus('rejected', data.item.id)"
                                >
                                    İptal Edildi
                                </CButton>
                                <CButton
                                    v-if="data.item.status === 'sent'"
                                    block
                                    variant="outline"
                                    color="danger"
                                    v-on:click.stop="changeOrderStatus('returned', data.item.id)"
                                >
                                    Geri Döndü
                                </CButton>
                            </td>
                        </template>
                        <template #changeType="data">
                            <td>
                                <CButton
                                    block
                                    variant="outline"
                                    :color="data.item.type === 'REAL' ? 'success' : 'warning'"
                                    v-on:click.stop="changeOrderType(data.item.type === 'TEST' ? 'REAL' : 'TEST', data.item.id)"
                                >
                                    {{ data.item.type === 'TEST' ? 'Gerçek Sipariş Yap' : 'Test Siparişi Yap' }}
                                </CButton>
                            </td>
                        </template>
                    </CDataTable>
                </CCardBody>
                <CCardFooter>
                    <CPagination
                        align="center"
                        :pages="Math.ceil(totalCount / limit)"
                        :active-page.sync="page"
                    />
                </CCardFooter>
            </CCard>
        </CCol>
    </CRow>
</template>

<script>
import axios from 'axios';

import { getOrderStatus, getPaymentMethod } from '../../utils';


export default {
    name: 'OrderList',

    data () {
        return {
            alert: {
                isOpen: false,
                color: '',
                message: ''
            },
            orders: [],
            fields: [
                {
                    key: 'fullName',
                    label: 'İsim',
                    _classes: 'font-weight-bold'
                },
                {
                    key: 'paymentMethod',
                    label: 'Ödeme yöntemi'
                },
                {
                    key: 'status',
                    label: 'Durumu',
                },
                {
                    key: 'info',
                    label: 'Bilgilendirme'
                },
                {
                    key: 'surveillance',
                    label: 'Şüpheli hareket ekle',
                },
                {
                    key: 'changeStatus',
                    label: 'Sipariş durumunu değiştir',
                },
                {
                    key: 'changeType',
                    label: 'Sipariş tipini değiştir',
                }
            ],
            page: 1,
            limit: 10,
            totalCount: 0,
            activeStatus: 'all',
            searchName: '',
            searchEmail: '',
            searchPhone: '',
        }
    },

    watch: {
        $route: {
            immediate: true,
            async handler (route) {
                let isChanged = false;
                if (route.query && route.query.page) {
                    this.page = Number(route.query.page);
                    isChanged = true;
                }
                if (route.query && route.query.limit) {
                    this.limit = Number(route.query.limit);
                    isChanged = true;
                }

                if (isChanged) {
                    await this.loadOrders();
                }
            }
        },
        page: function(newPage) {
            this.$router.push({ query: { page: newPage } });
        }
    },

    methods: {
        getOrderStatus: getOrderStatus,
        getPaymentMethod: getPaymentMethod,

        async loadOrders(headers) {

            const response = await axios({
                method: 'get',
                url: '/orders/paginate',
                headers: {
                    'X-Page': this.page,
                    'X-Limit': this.limit,
                    'X-Status': this.activeStatus,
                    'X-Name': encodeURI(this.searchName),
                    'X-Email': this.searchEmail,
                    'X-Phone': this.searchPhone,
                },
            });
            this.orders = response.data.results;
            this.totalCount = response.data.count;
        },

        rowClicked (item) {
            this.$router.push({ path: `${item._id}` });
        },

        redirectToSurveillanceCreate(orderId) {
            this.$router.push({
                query: { orderId },
                path: '/surveillances/create'
            });
        },

        async changeStatus(status) {
            this.activeStatus = status;
            await this.loadOrders();
        },

        async changeOrderStatus(status, orderId) {
            const response = await axios({
                method: 'put',
                url: `/orders/${orderId}/status`,
                data: { status }
            });

            if (response && response.status === 200) {
                this.alert = {
                    isOpen: true,
                    color: 'success',
                    message: 'Siparişin durumu başarıyla değiştirildi'
                };

                if (status === 'RETURNED') {
                    this.redirectToSurveillanceCreate(orderId);
                }

                await this.loadOrders();
            } else {
                this.alert = {
                    isOpen: true,
                    color: 'danger',
                    message: 'İşlem gerçekleştirilirken bir hata oluştu'
                };
            }
        },

        async changeOrderType(type, orderId) {
            const response = await axios({
                method: 'put',
                url: `/orders/${orderId}/type`,
                data: { type }
            });

            if (response && response.status === 200) {
                this.alert = {
                    isOpen: true,
                    color: 'success',
                    message: 'Siparişin durumu başarıyla değiştirildi'
                };

                await this.loadOrders();
            } else {
                this.alert = {
                    isOpen: true,
                    color: 'danger',
                    message: 'İşlem gerçekleştirilirken bir hata oluştu'
                };
            }
        },

        closeAlert() { this.alert.isOpen = false; },

        async search() {
            this.page = 1;
            await this.loadOrders();
        }
    },

    mounted() {
        this.loadOrders();
    }
}
</script>
